var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"title":"Historial de Notificaciones"}},[_c('div',{staticClass:"list-group history-view"},[_vm._l((_vm.notifications),function(item){return _c('div',{key:item.id,staticClass:"list-group-item text text-muted text-break"},[_c('div',{staticClass:"mt-1",staticStyle:{"width":"5%","float":"left","text-align":"center"}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"BookmarkIcon","size":"20"}})],1),_c('div',{staticStyle:{"width":"95%","float":"right"}},[(
                  item.type == 'App\\Notifications\\BillingErrorNotification'
                )?_c('div',{staticClass:"p-1 text-capitalize"},[(item.read_at)?_c('span',{staticClass:"badge badge-default float-right"},[_vm._v("Leido: "+_vm._s(_vm._f("date_format")(item.read_at)))]):_vm._e(),_c('span',[_vm._v(_vm._s(item.data.text))]),_c('div',{staticClass:"pt-1"},[_c('b-button',{attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.onNotificaion(item)}}},[_vm._v("Ver detalle")])],1)]):(
                  item.type ==
                  'App\\Notifications\\KeopsCustomerErrorKeopsNotification'
                )?_c('div',{staticClass:"p-1 text-capitalize"},[(item.read_at)?_c('span',{staticClass:"badge badge-default float-right",staticStyle:{"color":"#4b4b4b !important"}},[_vm._v("Leido: "+_vm._s(_vm._f("date_format")(item.read_at)))]):_vm._e(),_c('span',[_vm._v(_vm._s(item.data.text))]),_c('br'),_c('router-link',{staticClass:"text-primary mt-3",attrs:{"to":"/dashboard/profile"}},[_vm._v("Validar Perfil")])],1):(
                  item.type == 'App\\Notifications\\StoresNotification'
                )?_c('div',{staticClass:"p-1"},[(item.read_at)?_c('span',{staticClass:"badge badge-default float-right",staticStyle:{"color":"#4b4b4b !important"}},[_vm._v("Leido: "+_vm._s(_vm._f("date_format")(item.read_at)))]):_vm._e(),_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"#5c5b5b !important"}},[_vm._v(_vm._s(item.data.title))]),_c('br'),_c('span',{staticStyle:{"color":"#928e8e !important"}},[_vm._v(_vm._s(item.data.body))])]):_c('div',{staticClass:"p-1"},[(item.read_at)?_c('span',{staticClass:"badge badge-secondary float-right",staticStyle:{"color":"#4b4b4b !important"}},[_vm._v("Leido: "+_vm._s(_vm._f("date_format")(item.read_at)))]):_vm._e(),_c('span',[_vm._v(_vm._s(item.data.text))])])])])}),(_vm.history && _vm.history.current_page < _vm.history.last_page)?_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":_vm.onLoadNextPage}},[_vm._v("Cargar mas notificaciones")]):_vm._e()],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }