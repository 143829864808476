<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card title="Historial de Notificaciones">
          <div class="list-group history-view">
            <div
              v-bind:key="item.id"
              v-for="item in notifications"
              class="list-group-item text text-muted text-break"
            >
              <div
                style="width: 5%; float: left; text-align: center"
                class="mt-1"
              >
                <feather-icon class="text-body" icon="BookmarkIcon" size="20" />
              </div>

              <div style="width: 95%; float: right">
                <div
                  class="p-1 text-capitalize"
                  v-if="
                    item.type == 'App\\Notifications\\BillingErrorNotification'
                  "
                >
                  <span
                    class="badge badge-default float-right"
                    v-if="item.read_at"
                    >Leido: {{ item.read_at | date_format }}</span
                  >
                  <span>{{ item.data.text }}</span>
                  <div class="pt-1">
                    <b-button
                      size="sm"
                      @click="onNotificaion(item)"
                      variant="secondary"
                      >Ver detalle</b-button
                    >
                  </div>
                </div>
                <div
                  class="p-1 text-capitalize"
                  v-else-if="
                    item.type ==
                    'App\\Notifications\\KeopsCustomerErrorKeopsNotification'
                  "
                >
                  <span
                    class="badge badge-default float-right"
                    style="color: #4b4b4b !important"
                    v-if="item.read_at"
                    >Leido: {{ item.read_at | date_format }}</span
                  >
                  <span>{{ item.data.text }}</span>
                  <br />
                  <router-link class="text-primary mt-3" to="/dashboard/profile"
                    >Validar Perfil</router-link
                  >
                </div>
                <div
                  class="p-1"
                  v-else-if="
                    item.type == 'App\\Notifications\\StoresNotification'
                  "
                >
                  <span
                    class="badge badge-default float-right"
                    style="color: #4b4b4b !important"
                    v-if="item.read_at"
                    >Leido: {{ item.read_at | date_format }}</span
                  >
                  <span class="font-weight-bold" style="color: #5c5b5b !important">{{
                    item.data.title
                  }}</span>
                  <br />
                  <span style="color: #928e8e !important">{{
                    item.data.body
                  }}</span>
                </div>

                <div class="p-1" v-else>
                  <span
                    class="badge badge-secondary float-right"
                    style="color: #4b4b4b !important"
                    v-if="item.read_at"
                    >Leido: {{ item.read_at | date_format }}</span
                  >
                  <span>{{ item.data.text }}</span>
                </div>
              </div>
            </div>
            <b-button
              block
              variant="primary"
              v-if="history && history.current_page < history.last_page"
              @click="onLoadNextPage"
              >Cargar mas notificaciones</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserService from "@/services/UserService";

export default {
  name: "history-notification",
  data() {
    return {
      history: null,
      notifications: [],
    };
  },
  methods: {
    loadData(page) {
      UserService.historyNotifications(page).then((response) => {
        this.history = response.data.history;
        this.notifications = this.notifications.concat(this.history.data);
      });
    },
    onReadAll() {
      UserService.readAllNotifications().then(() => {
        this.notifications = [];
        this.loadData(1);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Crear`,
            text: `Operación exitosa`,
            icon: "InfoIcon",
            variant: "info",
          },
        });
      });
    },
    onNotificaion(item) {
      if (item.type == "App\\Notifications\\BillingErrorNotification") {
        this.$router.push("/dashboard/notifications/bill-error/" + item.id);
      }
    },
    onLoadNextPage() {
      this.loadData(this.history.current_page + 1);
    },
  },
  mounted() {
    this.loadData(1);
  },
};
</script>

<style lang="scss">
.history-view {
  height: 400px;
  overflow-y: auto;
}

.card-title {
  padding: 10px;
}
</style>
